import { Button, Typography } from '@/components';
import { RTEmbeddedEntriesMapping, RichTextEntries, TextStyles } from '@/constants';
import { useGlobalState } from '@/hooks';
import { ButtonVariant, type IRichText } from '@/interfaces';
import { type Options as RenderDocumentOptions, documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import React from 'react';
import {
  Blockquote,
  BoldText,
  IframeContainer,
  Image,
  ImageContainer,
  ItalicText,
  OlList,
  Paragraph,
  RichTextContainer,
  Table,
  TableCell,
  TableHeadCell,
  TableRow,
  UlList,
  UnderlineText,
  VideoContainer,
} from './RichText.styles';

const YOUTUBE_DOMAIN = 'youtube.com';
const YOUTUBE_SHORT_LINK_DOMAIN = 'youtu.be';

const RichText: React.FC<{
  richText: IRichText;
  hasWall?: boolean;
  pageTitle?: string;
}> = (props) => {
  const { hasWall, richText, pageTitle } = props;
  const { isLoggedIn, getProperImageUrl } = useGlobalState();

  if (!props.richText || !props.richText?.links) return null;

  const renderOptions = (links: any): RenderDocumentOptions => {
    const assetMap = new Map();

    for (const asset of links.assets.block) {
      if (asset?.sys) {
        assetMap.set(asset.sys.id, asset);
      }
    }

    const entryMap = new Map();
    for (const entry of links.entries.block) {
      if (entry?.sys) {
        entryMap.set(entry.sys.id, entry);
      }
    }

    return {
      ...documentToReactComponentsOptions,
      renderNode: {
        [BLOCKS.HEADING_1]: (_, children) => (
          <Typography type='h1' variant={TextStyles['Heading 1']}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_2]: (_, children) => (
          <Typography type='h2' variant={TextStyles['Heading 2']}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_3]: (_, children) => (
          <Typography type='h3' variant={TextStyles['Heading 3']}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_4]: (_, children) => (
          <Typography type='h4' variant={TextStyles['Heading 4']}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_5]: (_, children) => (
          <Typography type='h5' variant={TextStyles['Heading 5']}>
            {children}
          </Typography>
        ),
        [BLOCKS.HEADING_6]: (_, children) => (
          <Typography type='h6' variant={TextStyles['Heading 6']}>
            {children}
          </Typography>
        ),
        [BLOCKS.PARAGRAPH]: (_, children) => {
          const reactChildren = React.Children.toArray(children);
          if (reactChildren?.some((child: any) => child?.type === 'code')) {
            return <Paragraph className='rt-caption-text'>{children}</Paragraph>;
          }
          return <Paragraph>{children}</Paragraph>;
        },
        [BLOCKS.QUOTE]: (_, children) => <Blockquote>{children}</Blockquote>,
        [BLOCKS.UL_LIST]: (_, children) => <UlList>{children}</UlList>,
        [BLOCKS.OL_LIST]: (_, children) => <OlList>{children}</OlList>,
        [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
        [INLINES.HYPERLINK]: ({ data }, children) => {
          const isContainsYoutubeDomain =
            data.uri.indexOf(YOUTUBE_DOMAIN) !== -1 || data.uri.indexOf(YOUTUBE_SHORT_LINK_DOMAIN) !== -1;
          // rel: related videos are videos on the channel
          // modestbranding: hide Youtube's logo

          // Get youtube ID
          // eslint-disable-next-line no-useless-escape
          const regex =
            /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
          const embedIds = data.uri.match(regex);
          const isContainYoutubeEmbedIds = embedIds && embedIds.length > 0;

          if (isContainsYoutubeDomain && isContainYoutubeEmbedIds) {
            const embedId = embedIds[1];

            return (
              <IframeContainer>
                <iframe
                  width='500'
                  height='294'
                  src={`https://www.youtube.com/embed/${embedId}?&rel=0&modestbranding=1`}
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </IframeContainer>
            );
          }
          return (
            <Button variant={ButtonVariant.Hyperlink} href={data.uri} openInNewTab>
              {children}
            </Button>
          );
        },
        [BLOCKS.TABLE]: (_, children) => (
          <Table>
            <tbody>{children}</tbody>
          </Table>
        ),
        [BLOCKS.TABLE_ROW]: (_, children) => <TableRow>{children}</TableRow>,
        [BLOCKS.TABLE_CELL]: (_, children) => <TableCell>{children}</TableCell>,
        [BLOCKS.TABLE_HEADER_CELL]: (_, children) => <TableHeadCell>{children}</TableHeadCell>,
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const asset = assetMap.get(node.data.target.sys.id);
          if (!asset) return;

          switch (asset.contentType) {
            case 'video/mp4': //.mp4
            case 'video/ogg': //.ogv
            case 'video/webm': //.webm
              return (
                <VideoContainer>
                  <video src={asset.url} controls>
                    <source src={asset.url} type={asset.contentType} />
                  </video>
                </VideoContainer>
              );
            case 'video/quicktime': //.mov
              return (
                <VideoContainer>
                  <video src={asset.url} controls />
                </VideoContainer>
              );
            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/svg+xml':
            case 'image/webp':
            case 'image/gif':
              return (
                <ImageContainer>
                  <Image src={getProperImageUrl(asset)} alt={asset.description} />
                  {/* {asset.description && <ImageDesc>{asset.description}</ImageDesc>} */}
                </ImageContainer>
              );
            default:
              return null;
          }
        },
        [BLOCKS.EMBEDDED_ENTRY]: (node) => {
          const entry = entryMap.get(node.data.target.sys.id);
          if (!entry) return;
          if (Object.values(RichTextEntries).includes(entry.__typename)) {
            const typename: RichTextEntries = entry.__typename;
            const RichTextEmbeddedEntry = RTEmbeddedEntriesMapping[typename];
            if (!RichTextEmbeddedEntry) return null;
            return <RichTextEmbeddedEntry {...entry} isInsideRT isLoggedIn={isLoggedIn} pageTitle={pageTitle} />;
          }
          return null;
        },
      },
    };
  };

  const documentToReactComponentsOptions: RenderDocumentOptions = {
    renderText: (text) =>
      text
        .split('\n')
        .reduce(
          (children: any, textSegment: any, index: any) => [...children, index > 0 && <br key={index} />, textSegment],
          [],
        ),
    renderMark: {
      [MARKS.BOLD]: (text) => <BoldText>{text}</BoldText>,
      [MARKS.ITALIC]: (text) => <ItalicText>{text}</ItalicText>,
      [MARKS.UNDERLINE]: (text) => <UnderlineText>{text}</UnderlineText>,
    },
  };

  return (
    <RichTextContainer isLoggedIn={isLoggedIn} hasWall={!!hasWall} className={hasWall ? 'paywall' : ''}>
      {richText && documentToReactComponents(richText.json, renderOptions(richText.links))}
    </RichTextContainer>
  );
};

export default RichText;
