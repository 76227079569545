import type { IParcelEDD, IParcelEDDDTO } from '@/modules/pmt/interfaces/tracking/shipment.interfaces';

export const mapEdd = (edd: IParcelEDDDTO): IParcelEDD | undefined => {
  if (!edd?.display) return undefined;

  return {
    display: {
      start: edd.display.start,
      end: edd.display.end,
      timezoneEnd: edd.display.timezone_end,
      timezoneStart: edd.display.timezone_start,
      type: edd.display.type,
    },
  };
};
