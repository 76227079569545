import { Typography } from '@/components';
import { TextStyles, theme } from '@/constants';
import styled from 'styled-components';
import LeadGenBanner from '../LeadGenBanner';
import { styledText } from '../Typography/Typography.styles';

const defaultColor = `color: ${theme.colors.highEmphasisBlack};`;
const bulletDefaultSize = 6;
const firstNumberSize = 8;
const listInitialPadding = 24;

export const Paragraph = styled(Typography).attrs({ variant: TextStyles['Body Text Large'] })`
  &.rt-caption-text {
    margin-top: -${({ theme }) => theme.spacing['16']};
    text-align: center;
  }
`;

export const BoldText = styled.b`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const ItalicText = styled.i``;

export const UnderlineText = styled.span`
  text-decoration: underline;
`;

export const Blockquote = styled.blockquote`
  margin: ${({ theme }) => theme.spacing['24']} 0;
  margin-top: 0;
  padding-left: ${({ theme }) => theme.spacing['24']};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    left: 0;
    top: 0;
    background: ${({ theme }) => theme.colors.blue};
  }
`;

//! Configurable
export const UlList = styled.ul`
  padding-left: ${listInitialPadding + bulletDefaultSize / 2}px;
  ${defaultColor}
`;

//! Configurable
export const OlList = styled.ol`
  padding-left: ${listInitialPadding + firstNumberSize / 2}px;
  ${defaultColor}
`;

export const Image = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`;

export const ImageContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing['24']};
`;

export const IframeContainer = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing['24']};
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.27198%;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing['24']};

  > video {
    width: 100%;
    height: auto;
  }
`;

export const RichTextContainer = styled.div<{ isLoggedIn: boolean; hasWall: boolean }>`
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6,
  ${Paragraph} {
    ${defaultColor}
  }

  > h1 {
    margin: ${({ theme }) => theme.spacing['8']} 0;
  }

  > h2 {
    margin-bottom: ${({ theme }) => theme.spacing['16']};
  }

  > h3 {
    margin-bottom: ${({ theme }) => theme.spacing['12']};
  }

  ${Paragraph} {
    margin-bottom: ${({ theme }) => theme.spacing['24']};
  }

  > h4,
  > h5,
  > h6,
  li ${Paragraph} {
    margin-bottom: ${({ theme }) => theme.spacing['8']};
  }

  & > ${OlList},
  & > ${UlList} {
    margin-bottom: ${({ theme }) => theme.spacing[24]};
  }

  & > ${UlList} {
    padding-left: ${({ theme }) => theme.spacing[38]};
    li {
      list-style-type: none;
      position: relative;
      padding-left: ${({ theme }) => theme.spacing[8]};

      &::before {
        content: '';
        position: absolute;
        left: -${({ theme }) => theme.spacing[16]};
        top: ${({ theme }) => theme.spacing[6]};
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: ${({ theme }) => theme.colors.highEmphasisBlack};
      }
    }
  }

  & > ${UlList} > li ul {
    padding-left: ${({ theme }) => theme.spacing[16]};
  }

  & > ${UlList} > li > ul {
    > li {
      &::before {
        width: 8px;
        height: 8px;
        top: 7px;
        border: 1px solid ${({ theme }) => theme.colors.highEmphasisBlack};
        background: transparent;
      }
    }
  }

  & > ${UlList} > li > ul > li > ul {
    li {
      &::before {
        border-radius: unset;
      }
    }
  }

  // Update OL markers
  & > ${OlList} {
    padding-left: ${({ theme }) => theme.spacing[24]};

    list-style-position: inside;
    list-style-type: none;
    counter-reset: first;
    > li {
      padding-left: 0 !important;
    }
    > li > :first-child {
      padding-left: ${({ theme }) => theme.spacing[40]};
    }
    > li:before {
      counter-increment: first;
      content: counters(first, '.') '.';
    }
  }

  & > ${OlList} > li > ol {
    list-style-type: none;
    counter-reset: second;
    padding-left: ${({ theme }) => theme.spacing[48]};
    p {
      padding-left: ${({ theme }) => theme.spacing[40]};
    }
    > li:before {
      counter-increment: second;
      content: counters(first, '.') '.' counter(second);
    }
  }

  & > ${OlList} > li > ol > li ol { //format for all the next lists
    list-style-type: none;
    counter-reset: third;
    padding-left: ${({ theme }) => theme.spacing[48]};
    > li:before {
      counter-increment: third;
      content: '(' counter(third, lower-latin) ')';
    }
  }

  & ol li:before {
    display: block;
    position: absolute;
    ${styledText['Body Text Large']}
  }

  & > ${OlList} > li > ol > li {
    &:first-child {
      margin-top: 0;
    }
  }

  & > ${OlList} > li {
    &:before,
    > :first-child > :first-child {
      display: inline-block;
    }
  }

  ${(props) =>
    !props.isLoggedIn &&
    props.hasWall &&
    `
    & > :nth-last-child(2) {
      margin-bottom: 0;
      position: relative;

      ::after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 171.38%);
        width: 100%;
        height: 120px;
      }
    }
  `}

  ${(props) =>
    !props.hasWall &&
    `
    & > :last-child {
      margin-bottom: 0;
    }
  `}
`;

export const LeadGenBannerEntry = styled(LeadGenBanner)`
  margin-bottom: ${({ theme }) => theme.spacing['24']};
`;

export const Table = styled.table({
  borderCollapse: 'collapse',
  borderSpacing: 0,
  width: '100%',
});

export const TableRow = styled.tr({
  borderBottom: `1px solid ${theme.colors.lightSilver}`,
});

export const TableCell = styled.td({
  padding: theme.spacing[16],
  lineHeight: theme.lineHeight[20],
  color: theme.colors.darkGray,
  backgroundColor: theme.colors.highEmphasisWhite,
  border: `1px solid ${theme.colors.lightSilver}`,
  verticalAlign: 'baseline',

  'p:last-child': {
    marginBottom: '0 !important',
  },
});

export const TableHeadCell = styled(TableCell)({
  background: theme.colors.lightBlue,

  'p:last-child': {
    marginBottom: '0 !important',
  },
});
