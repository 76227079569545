export type TEmailAccountDTO = {
  id: string;
  user_id: string;
  created_date: string;
  last_updated: string;
  email_address: string;
  realtime_sync_activated: boolean;
};

export type TListEmailSyncAccountsDTO = {
  status: string;
  data: {
    total: number;
    emails: TEmailAccountDTO[];
    maximum_account_allowed: number;
  };
};

export type IEmailAccount = {
  id: string;
  userId: string;
  createdDate: string;
  lastUpdated: string;
  emailAddress: string;
  realtimeSyncActivated: boolean;
};

export type IEmailSyncAccount = {
  total: number;
  emails?: IEmailAccount[];
  maximumAccountAllowed: number;
};

export enum ToggleEmailSyncAccountErrorCode {
  INVALID_TOKEN = 'INVALID_TOKEN',
}

export type ToggleEmailSyncAccountResponse = {
  data: TEmailAccountDTO | null;
  errors?: {
    code: ToggleEmailSyncAccountErrorCode;
  };
  message: string;
  status: number;
  status_code: number;
};
