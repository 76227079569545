// Reference: https://parcelperform.atlassian.net/wiki/spaces/PM/pages/3127279699/Orion-18+Create+Update+Shipments+PMT+UI+Results
import dayjs from 'dayjs';
import type { IShipmentData } from '../interfaces';

export const isEddAvailable = (trackingData?: IShipmentData) => {
  if (!trackingData || !trackingData.edd?.display) return false;

  const { start } = trackingData.edd.display;

  if (!start) {
    return false;
  }

  return !dayjs(start).isBefore(dayjs());
};

// Disclaimer: Valid for PP UTC format only
export const isEddUtc = (time?: string) => {
  if (!time || typeof time !== 'string') return false;

  return time.charAt(time.length - 1) === 'Z';
};
