import {
  EventPhaseKey,
  type ICarrierByParcel,
  type INotiButtonStateByParcel,
  type IShipment,
  type ITrackingEventDateOptions,
  type ITrackingInput,
  ParcelEventAnalyticsType,
  ParcelStatus,
  ShipmentSearchStatus,
  ShipmentStage,
  ShipmentStatus,
} from './common.interfaces';
import type {
  IEmailAccount,
  IEmailSyncAccount,
  TEmailAccountDTO,
  TListEmailSyncAccountsDTO,
  ToggleEmailSyncAccountResponse,
} from './emailSync.interfaces';
import { ToggleEmailSyncAccountErrorCode } from './emailSync.interfaces';
import type { GtmClickFilter, GtmClickParcel } from './gtm.interfaces';
import type {
  ICarrier,
  ICarrierDTO,
  ICarrierWithFirstFoundEvent,
  IEventTypeMasterDataDTO,
  ILineItemsDTO,
  IMerchantInfo,
  IMerchantInfoDTO,
  IOrderTracking,
  IOrderTrackingDTO,
  IParcel,
  IParcelDataDTO,
  IParcelEvent,
  IParcelEventDTO,
  IParcelOrgDictDTO,
  IShipmentAdditionalInfo,
  IShipmentData,
  IShipmentDataDTO,
  IShipmentLineItem,
} from './shipment.interfaces';
import { ShipmentSubscribeState } from './subscribe.interfaces';
import {
  CarrierDropdownState,
  type IMilestoneCondition,
  type ITrackingProgressTracker,
  TrackingMilestone,
} from './trackingEventCard.interfaces';
import {
  type IPmtOnboardingPopup,
  type ITrackingLeftPanel,
  type ITrackingRightPanel,
  PmtOnboardingPopupCta,
  PmtSignUpStep,
} from './trackingOnboardingPopup.interfaces';
import type { IMainTranslation, INotificationTranslation, ITrackingTranslation } from './translation.interfaces';

export type {
  ICarrier,
  ICarrierWithFirstFoundEvent,
  IParcelEvent,
  IOrderTracking,
  IShipment,
  IShipmentData,
  IParcel,
  IShipmentAdditionalInfo,
  IShipmentLineItem,
  ITrackingProgressTracker,
  IMilestoneCondition,
  ITrackingEventDateOptions,
  ITrackingInput,
  ICarrierByParcel,
  INotiButtonStateByParcel,
  IMainTranslation,
  ITrackingTranslation,
  INotificationTranslation,
  GtmClickFilter,
  GtmClickParcel,
  IPmtOnboardingPopup,
  ITrackingLeftPanel,
  ITrackingRightPanel,
  IEmailSyncAccount,
  IEmailAccount,
  TEmailAccountDTO,
  TListEmailSyncAccountsDTO,
  IShipmentDataDTO,
  IMerchantInfoDTO,
  IMerchantInfo,
  IParcelOrgDictDTO,
  ICarrierDTO,
  IEventTypeMasterDataDTO,
  IParcelEventDTO,
  IParcelDataDTO,
  ILineItemsDTO,
  IOrderTrackingDTO,
  ToggleEmailSyncAccountResponse,
};

export {
  ParcelEventAnalyticsType,
  ParcelStatus,
  EventPhaseKey,
  ShipmentStatus,
  ShipmentSearchStatus,
  ShipmentStage,
  CarrierDropdownState,
  ShipmentSubscribeState,
  PmtSignUpStep,
  PmtOnboardingPopupCta,
  TrackingMilestone,
  ToggleEmailSyncAccountErrorCode,
};
