import type { IParcelDataDTO, IParcelEvent } from '@/modules/pmt/interfaces';
import { mapCarrier, mapParcelEvent } from '.';
import type { IParcel, IParcelEventDTO } from '../interfaces';

export const mapParcel = (parcel?: IParcelDataDTO): IParcel | undefined => {
  if (!parcel) return undefined;

  return {
    id: parcel.parcel_id,
    parcelId: parcel.parcel_id,
    carrier: mapCarrier(parcel.carrier),
    status: parcel.status,
    events:
      parcel.events?.reduce((acc: IParcelEvent[], event: IParcelEventDTO) => {
        const mappedEvent = mapParcelEvent(event);
        if (mappedEvent) acc.push(mappedEvent);
        return acc;
      }, []) ?? [],
    lastEvent: mapParcelEvent(parcel.last_event),
    lastValidEvent: mapParcelEvent(parcel.last_valid_event),
  };
};
