import { SignUpSource } from '@/constants';
import { umsFetcher } from '@/fetchers/umsAPI/base.fetchers';
import type { TListEmailSyncAccountsDTO, ToggleEmailSyncAccountResponse } from '@/modules/pmt/interfaces';
import type { CodeResponse } from '@react-oauth/google';

type TListEmailSyncAccounts = (args: {
  userUuid: string;
}) => Promise<TListEmailSyncAccountsDTO | undefined>;

export const listEmailSyncAccounts: TListEmailSyncAccounts = async ({ userUuid }) => {
  try {
    const response = await umsFetcher.get('email-accounts', { accessToken: userUuid });
    if (response.ok) {
      return await response.json();
    }
    return Promise.reject(response);
  } catch (err) {
    console.error('Network error');
    throw err;
  }
};

type TToggleEmailSyncAccount = (args: {
  userUuid: string;
  emailAccountId: string;
  realtimeSyncActivated: boolean;
  code?: string;
  redirectUri?: string;
}) => Promise<ToggleEmailSyncAccountResponse | undefined>;

export const toggleEmailSyncAccount: TToggleEmailSyncAccount = async ({
  userUuid,
  emailAccountId,
  realtimeSyncActivated,
  code,
  redirectUri,
}) => {
  try {
    const response = await umsFetcher.patch(
      `email-accounts/${emailAccountId}`,
      { realtime_sync_activated: realtimeSyncActivated, code, redirect_uri: redirectUri },
      { accessToken: userUuid },
    );

    const data = await response.json();
    const dataWithStatus = { ...data, status: response.status };
    return dataWithStatus;
  } catch (err) {
    console.error('Network error');
    throw err;
  }
};

type CountryConfig = (args: { countryCode?: string }) => Promise<boolean | undefined>;

export const getCountryConfig: CountryConfig = async ({ countryCode }) => {
  try {
    const res = await umsFetcher.get(`emailsync/country-config/${countryCode}`);
    const data = await res.json();
    return data?.is_allowed;
  } catch (err: any) {
    console.error('get country config ', err);
  }
};

export type AddEmailPayload = {
  code: CodeResponse['code'];
  signUpSource: SignUpSource;
  newsletterOptIn: boolean;
  scope: string;
  country?: string;
  fromPpEmail?: string;
  userId?: string;
  redirectUri: string;
};

export const addEmailAccount = async ({
  code,
  signUpSource = SignUpSource.PMT,
  newsletterOptIn = false,
  country,
  fromPpEmail,
  userId,
  redirectUri,
}: AddEmailPayload) => {
  try {
    const response = await umsFetcher.post(
      'email-accounts/add',
      {
        code,
        sign_up_source: signUpSource,
        newsletter_opt_in: newsletterOptIn,
        country,
        from_pp_email: fromPpEmail,
        redirect_uri: redirectUri,
      },
      { accessToken: userId },
    );

    if (response.ok) {
      return await response.json();
    }

    const errorText = await response.text();
    throw JSON.parse(errorText);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
